import { Card, Typography, CardContent, Button, CardActions } from "@mui/material";
import P4lIcon from "./P4lIcon";
import { Link } from "react-router-dom";
import { motion, useScroll, useTransform } from "framer-motion";
import { useState, useEffect } from "react";

function CardMessageButton({ title, text, subText, buttonText, buttonLink, buttonType, icon, onClick, emoji, animate, image }) {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    if (!animate) return; // If animate=false, do nothing

    const updateScroll = () => {
      setIsScrolled(window.scrollY > 25); // Detect scroll position
    };

    window.addEventListener("scroll", updateScroll);
    return () => window.removeEventListener("scroll", updateScroll);
  }, [animate]);

  return (
    <motion.div
      animate={animate ? { scale: isScrolled ? 0.9 : 1 } : {}}
      transition={{ duration: 0.3 }}
      className={`${animate && isScrolled ? 'w-fit gap-0 max-w-52 h-52' : 'w-full md:w-1/2 lg:w-1/3 xl:w-1/4'} flex justify-center`}
    >
      <Card
        initial={{ opacity: 1 }}
        animate={animate ? { height: isScrolled ? "150px" : "auto" } : {}}
        transition={{ duration: 0.3 }}
        style={{
          WebkitBoxShadow: "0px 0px 12px 0px #bdcdd1", // Adding subtle shadow effect
          maxWidth: "100%",
        }}
        className={`${animate && isScrolled ? ' gap-0' : 'gap-6'} p-4 flex flex-col justify-start w-full `}
      >
        {buttonLink && animate && isScrolled ? (
          <Link to={buttonLink} style={{ textDecoration: "none", color: "inherit" }}>
            <CardContent sx={{ paddingY: 0 }} >
              <div className="flex flex-col gap-4 items-center ">
                {icon && <P4lIcon icon={`${icon} text-[2rem]`} size="small" />}
                {emoji && <span className="text-[2rem] text-center">{emoji}</span>}
                {image && <img  src={image} alt="Card Image" className="w-auto h-[2rem] " />}
                <Typography className="flex items-center justify-center text-xs"
                  fontWeight={"bold"} variant="subtitle2"
                  sx={{ color: "primary.main", textAlign: "center", fontSize: "0.7rem" }}>
                  {text}
                </Typography>
              </div>
            </CardContent>
          </Link>
        ) : (
          <CardContent sx={{ paddingY: 0 }} className="flex flex-col gap-6 justify-center ">
            <div className="flex flex-col gap-2 items-center">
              <div className="flex flex-col items-center ">
                {icon && <P4lIcon icon={`${icon} text-[2rem]`} size="small" />}
                {emoji && <span className="text-[2rem] text-center">{emoji}</span>}
                {image && <img  src={image} alt="Card Image" className="w-auto h-[2rem] " />}
                <Typography className="min-h-[4rem] flex items-center justify-center text-xs" fontWeight={"bold"} variant="subtitle2" sx={{ color: "primary.main", textAlign: "center", fontSize: "0.7rem" }}>
                  {text}
                </Typography>
              </div>
              <Typography className="whitespace-nowrap" variant="body2" sx={{ color: "primary.main" }}>
                {subText ? subText : <p className="opacity-0">sem subtitulo</p>}
              </Typography>
            </div>
          </CardContent>
        )}
        <motion.div
          animate={animate ? { opacity: isScrolled ? 0 : 1 } : {}}
          transition={{ duration: 0.3 }}
        >
          {((animate && !isScrolled) || (!animate)) && <CardActions className="mt-auto mx-auto flex justify-center">
            {buttonLink ? (
              <Button size="small" variant={buttonType ? buttonType : "contained"}>
                <Link to={buttonLink} style={{ textDecoration: "none", color: "inherit" }}>
                  {buttonText}
                </Link>
              </Button>
            ) : (
              <Button size="small" variant={buttonType ? buttonType : "contained"} onClick={onClick}>
                {buttonText}
              </Button>
            )}
          </CardActions>}
        </motion.div>
      </Card>
    </motion.div>
  );
}

export default CardMessageButton;
