import React from 'react';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';

function P4lIcon(props) {
  const { ariaLabel, edge, onClick, onMouseDown, href, size, disableRipple=true, icon, iconUrl, className, badgeContent, ...otherProps } = props;

  // Check if badgeContent is provided and is not null
  const shouldShowBadge = badgeContent !== undefined && badgeContent !== null;

  // Determine if the icon is a URL or a class name
  const isIconUrl = iconUrl !== undefined && iconUrl !== null;

  const IconContent = isIconUrl ? (
    <img className='w-6' src={iconUrl} alt="icon" />

  ) : (
    <span className={`${icon ? icon : ''} w-fit h-fit text-left ${className || 'default-icon-class'}`} />
  );

  return (
    <IconButton
      aria-label={ariaLabel}
      edge={edge}
      onClick={onClick}
      onMouseDown={onMouseDown}
      href={href}
      size={size ? size : 'small'}
      disableRipple={disableRipple ? disableRipple : false}
      {...otherProps}
    >
      {shouldShowBadge ? (
        <Badge badgeContent={badgeContent} color="error">
          {IconContent}
        </Badge>
      ) : (
        IconContent
      )}
    </IconButton>
  );
}

export default P4lIcon;
