import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { Box } from "@mui/material";

function HourGlass({ images, type }) {
    console.log(images)

    const [currentImage, setCurrentImage] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImage((currentImage) => (currentImage + 1) % images.length);
        }, 1000);  // Change image every 2 seconds
        return () => clearInterval(interval);
    }, []);

    const variants = {
        enter: { opacity: 0, x: 0 },
        center: { opacity: 1, x: 0 },
        exit: { opacity: 1, x: -100 }
    };

    const sliderContent = (
        <Box className="flex flex-col items-center justify-center min-h-[50vh] " >
            <motion.div
                key={currentImage}
                initial="enter"
                animate="center"
                exit="exit"
                variants={variants}
                transition={{ duration: 0.5 }}
                className="w-full flex justify-center items-center m-0 p-0"
            >
                <img src={images[currentImage]} alt={`Image ${currentImage}`}
                     className='w-[210px] object-contain' />
            </motion.div>
            <div className='text-center font-bold text-1.5xl mt-4'>
                Estamos a verificar os seus dados junto do Banco de Portugal
            </div>
        </Box>
    );

    return type ? sliderContent : (
        <Box sx={{
            display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh', width: '100%',
        }}>
            <div className='bg-white w-full flex justify-center items-center min-h-screen'>
                <img className='max-w-xs' src={`${process.env.PUBLIC_URL}/static/images/loading.gif`} alt="Loading" />
            </div>
        </Box>
    );
}

export default HourGlass;
