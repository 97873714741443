import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import Form from "../components/UI/Form";
import Input from "../components/UI/Input";
import { integrationInit } from "../utils/cms";
import { useNavigate } from "react-router-dom"; // For navigation
import { useLocation } from "react-router-dom";
const IntegrationLogin = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const integrationID = queryParams.get('id')?.toUpperCase();
console.log(integrationID);


  const [fieldValues, setFieldValues] = useState({
    login: "",
    password: "",
  });
  const [isLoading, setIsLoading] = useState(false); // Loading state for the button
  const navigate = useNavigate(); // For navigating to the notification page

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFieldValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };
  if (!integrationID) return;
  const handleSubmit = async (event) => {
    event.preventDefault();
    const { login, password } = fieldValues;

    setIsLoading(true); // Start loading

    try {
      // Call financeInit API with nif and password as arguments
      const initResponse = await integrationInit(integrationID,login, password);

      // Check if financeInit was successful
      if (initResponse.ok) {
        // Call financeAtCheck after financeInit


        // Handle success if both APIs succeed

          // Navigate to the notification page after both APIs succeed
          navigate("/notification", {
            state: {
              messages: {
                title: "integration-init",
                success: 'integration-init-success',
                instructions: "notification-integration-init-instructions",
              },
              buttons: [
                {
                  text: "back",
                  link: "/",
                },
              ],
            },
          });

      }
    } catch (error) {
      // Handle error
      console.error(error.message);
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  return (
    <Form buttonText={"Save"} onSubmit={handleSubmit} isLoading={isLoading}>
      <p className="mb-6">{"instructions"}</p>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <Input
              type="text"
              label={"Login"}
              name="login"
              value={fieldValues.login}
              onChange={handleChange}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <Input
              type="password"
              label={"Password"}
              name="password"
              value={fieldValues.password}
              onChange={handleChange}
            />
          </FormControl>
        </Grid>
      </Grid>
      <p className="mt-6" style={{ color: "#d32f2f", fontWeight: "bold" }}>{"disclaimer"}</p>
    </Form>
  );
};

export default IntegrationLogin;
