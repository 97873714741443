import React, { useState,useEffect } from 'react';
import { useLoaderData } from 'react-router-dom';
import Accordion from '../components/UI/Accordion';
import Chat from '../components/FloatingChat/Chat';
import ContentPage from './ContentPage';
import Swiper from '../components/Swiper/Swiper';
import { fetchFinancialDetails ,fetchFinancialDocumentsStatus} from '../utils/cms';
import { format } from 'date-fns';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { fetchProfileCompleteness, fetchSwiperImages } from '../utils/cms';
import { fetchCalendar } from '../utils/cms';
import AlertsContainer from '../components/UI/AlertMessage/AlertsContainer';
import ProfileProgressBar from '../components/ProfileProgressBar/ProfileProgressBar';
import Calendar from '../components/Calendar/Calendar';
import CardMessageButton from '../components/UI/CardMessageButton';
import { contentLoaderAlternative } from './ContentPage';
import { Accordion as AccordionMUI , AccordionDetails, AccordionSummary,Typography, CircularProgress } from '@mui/material';
function Home() {
  const loaderData = useLoaderData();
const { profileCompletenessItems , financialStatus ,financialDocumentsStatus,contentDataLoader,swiperImages} = loaderData;

const [numberEvents, setNumberEvents] = useState(null); // Loading state
/*const accordionData = {
  "seguros": [
  {
  "id": "saude",
  "title": "Seguro de Saúde",
  "description": "Tem 2 seguros subscritos.",
  "premium": 2290.68,
  "color": "#3232C8",
  "icon": "p4l-health",
  "cards": [
  {
  "fp": {
  "name": "Asisa",
  "logo": "/media/FP_asisa.jpg"
  },
  "name": "AP231031452306790090625",
  "total": 1330.2,
  "cost": 110.85,
  "link": "/pages/my-insurance-detail?stamp=INS-20241108095003-NwiaDFciZSfXadrgTFZqXeUKcAWkmYJ"
  },
  {
  "fp": {
  "name": "Fidelidade",
  "logo": "/media/FP_fidelidade.png"
  },
  "name": "AP230126449527615311574",
  "total": 960.48,
  "cost": 80.04,
  "link": "/pages/my-insurance-detail?stamp=INS-20241210034848-FFWCQFXhrsQgGkcWSAXBaJcwFbtKKiP"
  }
  ]
  },
  {
  "id": "general",
  "title": "Sem Ramo",
  "description": "Tem 2 seguros subscritos.",
  "premium": 15400,
  "color": "#000000",
  "icon": "fa-life-ring",
  "cards": [
  {
  "fp": {
  "name": "Sem seguradora definida",
  "logo": null
  },
  "name": "seguro de vida",
  "total": 15000,
  "cost": 333,
  "link": "/pages/my-insurance-detail?stamp=INS-20241112150919-dSPlIbCqBPOZsVWCChlvBhfqwTUySki"
  },
  {
  "fp": {
  "name": "Sem seguradora definida",
  "logo": null
  },
  "name": "Segura da casa de férias",
  "total": 400,
  "cost": 23,
  "link": "/pages/my-insurance-detail?stamp=INS-20241129105111-DKuBXKWoBHONrbkccAIgFovJrtCtntV"
  }
  ]
  },
  {
  "id": "acidentes-pessoais",
  "title": "Seguro de Acidentes Pessoais",
  "description": "Tem 1 seguro subscrito.",
  "premium": 388.32,
  "color": "#326432",
  "icon": "p4l-health",
  "cards": [
  {
  "fp": {
  "name": "MetLife",
  "logo": "/media/FP_metlife.png"
  },
  "name": "AP230127449535070039236",
  "total": 388.32,
  "cost": 32.36,
  "link": "/pages/my-insurance-detail?stamp=INS-20241210034848-ACMyyZhmUwKNtdbexNdCDqWsGBrBmXC"
  }
  ]
  },
  {
  "id": "automovel",
  "title": "Seguro Automóvel",
  "description": "Tem 3 seguros subscritos.",
  "premium": 1637.92,
  "color": "#323232",
  "icon": "p4l-car",
  "cards": [
  {
  "fp": {
  "name": "Ageas",
  "logo": "/media/FP_ageas_5W9Bdhb.png"
  },
  "name": "AP240812455166162510417",
  "total": 264.64,
  "cost": 66.16,
  "link": "/pages/my-insurance-detail?stamp=INS-20241210034848-eMkVsxMhxRInrpQInMPkNKNXaAvzRan"
  },
  {
  "fp": {
  "name": "Liberty Seguros",
  "logo": "/media/FP_liberty-seguros.png"
  },
  "name": "AP230309449946900354514",
  "total": 313.16,
  "cost": 78.29,
  "link": "/pages/my-insurance-detail?stamp=INS-20241210034848-hdaHnjgnzOqunqoTWHkXacFysXyioin"
  },
  {
  "fp": {
  "name": "Ageas",
  "logo": "/media/FP_ageas_5W9Bdhb.png"
  },
  "name": "AP230405450215378529051",
  "total": 1060.12,
  "cost": 265.03,
  "link": "/pages/my-insurance-detail?stamp=INS-20241210034848-NqZwKlXojJhqAwWAuEJzdnjhzLJOZdb"
  }
  ]
  },
  {
  "id": "ppr",
  "title": "PPR PU",
  "description": "Tem 1 seguro subscrito.",
  "premium": 300,
  "color": "#329632",
  "icon": "p4l-investment",
  "cards": [
  {
  "fp": {
  "name": "Fidelidade",
  "logo": "/media/FP_fidelidade.png"
  },
  "name": "AP231229452896121125116",
  "total": 300,
  "cost": 25,
  "link": "/pages/my-insurance-detail?stamp=INS-20241210034848-eosiNSTOByGETEILqmAvGHTWUSrQcBF"
  }
  ]
  },
  {
  "id": "vida",
  "title": "Ramo Vida",
  "description": "Tem 3 seguros subscritos.",
  "premium": 1969.2,
  "color": "#326464",
  "icon": "p4l-investment",
  "cards": [
  {
  "fp": {
  "name": "MetLife",
  "logo": "/media/FP_metlife.png"
  },
  "name": "AP230127449535069568981",
  "total": 1488.36,
  "cost": 124.03,
  "link": "/pages/my-insurance-detail?stamp=INS-20241210034848-gJrGQqJPLYHherZHEhZyKQpJTyInspi"
  },
  {
  "fp": {
  "name": "MetLife",
  "logo": "/media/FP_metlife.png"
  },
  "name": "AP230127449535070122454",
  "total": 413.4,
  "cost": 34.45,
  "link": "/pages/my-insurance-detail?stamp=INS-20241210034848-mrLqofSDIAHzikGWlyBeespfXODVfue"
  },
  {
  "fp": {
  "name": "MetLife",
  "logo": "/media/FP_metlife.png"
  },
  "name": "AP230605450827051825347",
  "total": 67.44,
  "cost": 5.62,
  "link": "/pages/my-insurance-detail?stamp=INS-20241210034848-OkbwssxgxZHVUWnEqYrosnyomTwDZtA"
  }
  ]
  },
  {
  "id": "multiriscos",
  "title": "Seguro Multiriscos",
  "description": "Tem 1 seguro subscrito.",
  "premium": 194.38,
  "color": "#3264C8",
  "icon": "p4l-investment",
  "cards": [
  {
  "fp": {
  "name": "Tranquilidade",
  "logo": "/media/FP_tranquilidade.png"
  },
  "name": "AP230126449527723109606",
  "total": 194.38,
  "cost": 194.38,
  "link": "/pages/my-insurance-detail?stamp=INS-20241210034848-YVBrUOdciEtbjCMwMOyuTczVmLpdjFz"
  }
  ]
  }
  ],
  "creditos": [
  {
  "id": "ccs",
  "title": "Cartão de Crédito",
  "description": "Tem 7 créditos subscritos.",
  "premium": 6171.99,
  "color": "red",
  "icon": "p4l-credit-cards",
  "link": "/page/my-credits",
  "cards": [
  {
  "fp": {
  "name": "bank-not-defined",
  "logo": null
  },
  "name": "Cartão de crédito - com período de free-float1",
  "total": 20.8,
  "cost": 0,
  "link": "/pages/my-credit-detail?stamp=CRD-20241108092806-dlJTFgmjMivZLTTvHuKBhkOPUKXmBam"
  },
  {
  "fp": {
  "name": "bank-not-defined",
  "logo": null
  },
  "name": "Cartão de crédito - sem período de free-float",
  "total": 1330.05,
  "cost": 0,
  "link": "/pages/my-credit-detail?stamp=CRD-20241108092806-eXyYgUumDtUcirlkRCWkBFnzXyotgTK"
  },
  {
  "fp": {
  "name": "bank-not-defined",
  "logo": null
  },
  "name": "Cartão de crédito - com período de free-float",
  "total": 20.8,
  "cost": 0,
  "link": "/pages/my-credit-detail?stamp=CRD-20241108092806-ksazYRXznajAwKHielAMAYlPyhqGWrc"
  },
  {
  "fp": {
  "name": "bank-not-defined",
  "logo": null
  },
  "name": "Cartão de crédito - com período de free-float",
  "total": 1135.93,
  "cost": 244.73,
  "link": "/pages/my-credit-detail?stamp=CRD-20241108092806-RhplFQHDfphtmZrqNzDfSDIEONnUsBz"
  },
  {
  "fp": {
  "name": "BPI",
  "logo": "/media/FP_bpi.png"
  },
  "name": "Cartão de crédito - com período de free-float",
  "total": 0,
  "cost": 0,
  "link": "/pages/my-credit-detail?stamp=CRD-20241210042220-fBEMcXUYLGLQOQkfUUEqcYVbacsAind"
  },
  {
  "fp": {
  "name": "WiZink",
  "logo": "/media/FP_wizink.png"
  },
  "name": "Cartão de crédito - sem período de free-float",
  "total": 2256.41,
  "cost": 0,
  "link": "/pages/my-credit-detail?stamp=CRD-20241210042221-IfcDbzbUHOUSReRICGVghNCjaYQrZwq"
  },
  {
  "fp": {
  "name": "Universo",
  "logo": "/media/FP_universo.png"
  },
  "name": "Cartão de crédito - com período de free-float",
  "total": 1408,
  "cost": 487.22,
  "link": "/pages/my-credit-detail?stamp=CRD-20241210042221-ZWnqFgroDgJTdLItEWjxdmZFoKYKhpz"
  }
  ]
  },
  {
  "id": "cps",
  "title": "Crédito Pessoal",
  "description": "Tem 4 créditos subscritos.",
  "premium": 0,
  "color": "blue",
  "icon": "p4l-credit-personal",
  "link": "/page/my-credits",
  "cards": [
  {
  "fp": {
  "name": "bank-not-defined",
  "logo": null
  },
  "name": "Facilidades de descoberto",
  "total": 0,
  "cost": 0,
  "link": "/pages/my-credit-detail?stamp=CRD-20241108092806-uvbuGoExEthqjcGHsXxeUXbaYQPaRMv"
  },
  {
  "fp": {
  "name": "bank-not-defined",
  "logo": null
  },
  "name": "Facilidades de descoberto - com domiciliação de ordenado e prazo de reembolso igual ou inferior a um",
  "total": 0,
  "cost": 0,
  "link": "/pages/my-credit-detail?stamp=CRD-20241108092807-rmqgbZeVDAjClGpDHuoUrViitUzoAvN"
  },
  {
  "fp": {
  "name": "BPI",
  "logo": "/media/FP_bpi.png"
  },
  "name": "Facilidades de descoberto",
  "total": 0,
  "cost": 0,
  "link": "/pages/my-credit-detail?stamp=CRD-20241210042220-UbTRHyRsVVhNfGLzcKbNEjbScTBWmuc"
  },
  {
  "fp": {
  "name": "Banco Santander Totta",
  "logo": "/media/FP_santander-totta.svg"
  },
  "name": "Facilidades de descoberto - com domiciliação de ordenado e prazo de reembolso igual ou inferior a um",
  "total": 0,
  "cost": 0,
  "link": "/pages/my-credit-detail?stamp=CRD-20241210042221-wdJTrhIvMZxbVjJOWMawfHLgjoCoYLc"
  }
  ]
  },
  {
  "id": "chs",
  "title": "Crédito Habitação",
  "description": "Tem 2 créditos subscritos.",
  "premium": 234400.72,
  "color": "green",
  "icon": "p4l-credit-house",
  "link": "/page/my-credits",
  "cards": [
  {
  "fp": {
  "name": "BPI",
  "logo": "/media/FP_bpi.png"
  },
  "name": "Crédito à habitação",
  "total": 152746.32,
  "cost": 1092.79,
  "link": "/pages/my-credit-detail?stamp=CRD-20241210042220-RbWXPkHaztRPrUjBALiteAcwPrBGTVQ"
  },
  {
  "fp": {
  "name": "Banco Santander Totta",
  "logo": "/media/FP_santander-totta.svg"
  },
  "name": "Crédito à habitação",
  "total": 81654.4,
  "cost": 421.88,
  "link": "/pages/my-credit-detail?stamp=CRD-20241210042221-lcwcUKufFmKmNrcHkCGirbdMlUsAKZo"
  }
  ]
  }
  ]
  }; */
  const issuesCount = profileCompletenessItems?.steps?.length || 0;
  const nextStep = profileCompletenessItems?.next_step?.length > 0 && profileCompletenessItems.next_step[0] || [];
  // const nextStep = [
  //   {
  //     url:"https://docs.p4lpro.com/cards/dap",
  //   }
  // ]
  useEffect(() => {
   if(nextStep.length ===0) return;
    window.location.href = nextStep.url;
  }, []);
/*  const chartData3 = {
    labels: ["Contas", "Poupança", "Investimentos", "Caridade"],
    data: [70, 20, 30, 10], // Data values received via JSON
    title:"teste",
    subtitle:"subteste"
  };*/
  //console.log(loaderData)
  return (
    <React.Fragment>


      <div className='flex flex-col gap-8 '>
        <div className='flex flex-col '>
        {profileCompletenessItems?.percentage <100 && <ProfileProgressBar progress={profileCompletenessItems.percentage} />}

        {/* Alerts Container */}
        {profileCompletenessItems?.steps?.length>0 && <AlertsContainer issuesCount={issuesCount} steps={profileCompletenessItems.steps || []} />}



        </div>

      <AccordionMUI sx={{ bgcolor: "white"}}>
          {numberEvents ===null ? <CircularProgress/> : <AccordionSummary   sx={{ bgcolor: "white"}}   expandIcon={<ExpandMoreIcon />}>
          <Typography className='flex gap-4 items-center' >
          <span className='p4l-calendar  text-blue-400  text-5xl'></span>
          {numberEvents > 0 ?
            <>

              {`Tem ${numberEvents} ${numberEvents ===1 ? "evento":"eventos"} para esta semana`} {/* Use translation with dynamic count */}
            </> : 'Não tem eventos esta semana'
          }
        </Typography>

          </AccordionSummary>}
          <AccordionDetails>
          <Calendar
          initialView="listWeek" // Define the initial view
          views={['listWeek']} // Define the available views
          updateNumberEvents={setNumberEvents}
          translations={loaderData.calendarData.translations_items.calendar}
        />
          </AccordionDetails>
          </AccordionMUI>
<div className='flex  justify-center items-center gap-6'>
  {profileCompletenessItems?.financial_health === false &&<CardMessageButton title="Saude Financeira" text="Ainda não calculou a saúde financeira" buttonText="Calcular" buttonLink="/pages/financial-health-summary" icon="p4l-lock text-pink-400 " animate={true} /> }
  {profileCompletenessItems?.financial_health === true && profileCompletenessItems?.financial_health_data && <CardMessageButton title="Saude Financeira" text={`Saúde Financeira ${profileCompletenessItems.financial_health_data.estado}`} buttonText="Ver mais" buttonLink="/pages/financial-health-summary" emoji={profileCompletenessItems.financial_health_data.emoji} animate={true}/> }
{financialStatus && financialStatus.official_status.at_status ==='GREEN' && financialDocumentsStatus?.overall_status!=="PENDING" && financialStatus.credentials_status.status !=='EXPIRED' && <CardMessageButton title="Portal das finanças" text="Situação fiscal regularizada" subText={`${format(new Date(financialStatus.official_status.last_updated), 'yyyy-MM-dd HH:mm:ss')} `} buttonText="Ver mais" buttonLink="/profile/financials" icon="p4l-check text-green-400" animate={true}/> }
{financialStatus && financialStatus.official_status.at_status !=='NOT_INIT' && financialDocumentsStatus?.overall_status==="PENDING" && financialStatus.credentials_status.status !=='EXPIRED' && <CardMessageButton title="Portal das finanças" text="Precisamos mais informação"  buttonText="Obter Documentos" buttonLink="/profile/financials" icon="p4l-warning text-orange-400" animate={true} /> }
{financialStatus && financialStatus.official_status.at_status ==='RED' && financialDocumentsStatus?.overall_status!=="PENDING" && financialStatus.credentials_status.status !=='EXPIRED' && <CardMessageButton title="Portal das finanças" text="A sua situação fiscal não está regularizada. Consulte o portal das finanças." buttonText="Portal das finanças" buttonLink="https://www.portaldasfinancas.gov.pt/" icon="p4l-cross text-red-400" animate={true}/>  }
{financialStatus && (financialStatus.credentials_status.status ==='NOT_INIT' ||financialStatus.credentials_status.status ==='EXPIRED' ) && <CardMessageButton title="Portal das finanças" text={financialStatus.credentials_status.status ==='NOT_INIT' ? "Ainda não configurou os dados do portal das finanças." : "A sua password das finanças expirou."}  buttonText="Preencher dados" buttonLink="/profile/financials" icon="p4l-lock text-pink-400" animate={true} /> }

</div>

        <div className="flex flex-col items-center ">
        { profileCompletenessItems?.accordionData && <Accordion accordionData={profileCompletenessItems.accordionData} /> }


       { /*<Accordion accordionData={accordionData}/>*/}





</div>
<Chat translations={contentDataLoader.translations_items.chat}/>
{swiperImages && swiperImages.length>0 && <Swiper images={swiperImages} />}



<div className='flex flex-wrap justify-center gap-6 w-full'>
       {/*profileCompletenessItems?.charts?.credits && <StackBarChartApex chartData={profileCompletenessItems.charts.credits} />*/}
       {/*profileCompletenessItems?.charts?.insurances &&<StackBarChartApex chartData={profileCompletenessItems.charts.insurances} /> */}
       {/*profileCompletenessItems?.charts?.financial_protection &&  <RadialChartApex chartData={profileCompletenessItems.charts.financial_protection} />*/ }
        {/*profileCompletenessItems?.charts?.financial_expenses &&  <PolarAreaChart chartData={profileCompletenessItems.charts.financial_expenses} /> */}
        {/*profileCompletenessItems?.charts?.financial_income &&  <DonutChart chartData={profileCompletenessItems.charts.financial_income} /> */}
        {/*profileCompletenessItems?.charts?.financial_income?.data.length>0 &&  <HalfDonutChart chartData={profileCompletenessItems.charts.financial_income} />*/ }

        </div>

        {/* Pass the fetched images to the Swiper component */}

        <ContentPage dataloader={contentDataLoader} />


      </div>


    </React.Fragment>
  );
}

export default Home;


export async function loader() {
  let financialDetailsData = null;
  let financialDocumentsStatus=null;

  let profileCompletenessData = null;
  let contentLoaderData = null;
  let swiperImagesData=[];
  let calendarData = null;

  try {
    financialDetailsData = await fetchFinancialDetails(); // Fetch financial details from API
  } catch (error) {
    console.error("Error fetching financial details:", error);
    // Optionally set fallback data here
  }

  try {
    calendarData = await fetchCalendar();
  } catch (error) {
    console.error("Error fetching calendar details:", error);
    // Optionally set fallback data here
  }

  try {
    financialDocumentsStatus = await fetchFinancialDocumentsStatus(); // Fetch financial details from API
  } catch (error) {
    console.error("Error fetching financial details:", error);
    // Optionally set fallback data here
  }



  try {
    profileCompletenessData = await fetchProfileCompleteness(); // Fetch profile completeness
  } catch (error) {
    console.error("Error fetching profile completeness:", error);
    // Optionally set fallback data here
  }

  try {
   contentLoaderData = await contentLoaderAlternative(); // Fetch profile completeness
  } catch (error) {
    console.error("Error fetching content page", error);
    // Optionally set fallback data here
  }
try {
    swiperImagesData = await fetchSwiperImages();

    }
    catch (error) {
    console.error("Error fetching content page", error);
    // Optionally set fallback data here
  }

  const data = {
    financialStatus: financialDetailsData,
    financialDocumentsStatus:financialDocumentsStatus,
    profileCompletenessItems: profileCompletenessData,
    contentDataLoader : contentLoaderData,
    swiperImages : swiperImagesData?.images,
    calendarData:calendarData,
  };

  return data;
}
