import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useNavigation, Link } from 'react-router-dom';
import { Box, Typography, CircularProgress } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslationsContext } from '../store/context/translations-context';
import Button from '../components/UI/Button'; // Custom Button Component
import { styled } from '@mui/material/styles';

function NotificationPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const navigation = useNavigation();
  const { messages, email, buttons, error } = location.state || {};
  const { requestTranslation } = useTranslationsContext();

  const [translations, setTranslations] = useState({
    notificationTitle: '',
    instructionsMessage: '',
    successMessage: '',
    buttonLabels: []
  });

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!messages) {
      navigate('/', { replace: true });
    }
  }, [messages, navigate]);

  useEffect(() => {
    const loadTranslations = async () => {
      const notificationTitle = await requestTranslation(messages?.title);
      const instructionsMessage = await requestTranslation(messages?.instructions || '');
      const successMessage = await requestTranslation(messages?.success || '');

      const buttonLabels = await Promise.all(
        buttons?.map(async (btn) => await requestTranslation(btn.text)) || []
      );

      setTranslations({ notificationTitle, instructionsMessage, successMessage, buttonLabels });
      setLoading(false);
    };

    loadTranslations();
  }, [requestTranslation, messages, buttons]);

  const isRouterLoading = navigation.state === 'loading';

  const StyledLink = styled(Link)(({ theme }) => ({
    textDecoration: 'none',
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    padding: '10px 24px',
    fontWeight: 'bold',
    borderRadius: '50px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    margin: '10px',
    display: 'inline-block',
    textAlign: 'center',
  }));

  return (
    <>
      {(loading || isRouterLoading) ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <CircularProgress size={50} />
        </Box>
      ) : (
        messages && (
          <div className="bg-grey p-6">
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              height="100vh"
              textAlign="center"
            >
              <Box
                sx={{
                  background: 'linear-gradient(to right, #252959 0%, #e62686 100%)',
                  borderRadius: '50%',
                  width: '100px',
                  height: '100px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {error ? (
                  <CloseIcon sx={{ fontSize: 50, color: '#fff' }} />
                ) : (
                  <CheckIcon sx={{ fontSize: 50, color: '#fff' }} />
                )}
              </Box>

              <Typography variant="h4" sx={{ fontWeight: 'bold', mt: 4 }}>
                {translations.notificationTitle}
              </Typography>
              <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 2 }}>
                {translations.successMessage}
              </Typography>
              <Typography
                variant="body1"
                sx={{ fontWeight: 'bold', mt: 2, color: 'primary.accent' }}
              >
                {translations.instructionsMessage} {email && <strong>{email}</strong>}
              </Typography>

              <Box sx={{ mt: 4 }}>
                {buttons?.map((btn, index) =>
                  isRouterLoading ? (
                    <Button
                      key={index}
                      isLoading
                      buttonText={translations.buttonLabels[index]}
                      sx={{
                        backgroundColor: 'primary.main',
                        '&:hover': { backgroundColor: 'primary.dark' },
                        color: 'white',
                        margin: '10px',
                        padding: '10px 24px',
                        borderRadius: '50px',
                        fontWeight: 'bold',
                      }}
                    />
                  ) : (
                    <StyledLink key={index} to={btn.link}>
                      {translations.buttonLabels[index]}
                    </StyledLink>
                  )
                )}
              </Box>
            </Box>
          </div>
        )
      )}
    </>
  );
}

export default NotificationPage;
