// ** React Imports
import React, { useState, Fragment,useEffect,useCallback } from 'react';

// ** MUI Imports
import Box from '@mui/material/Box';

import { styled } from '@mui/material/styles';

import MuiMenu from '@mui/material/Menu';
import MuiMenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { Tooltip } from '@mui/material';
import { useSnackBar } from '../../../store/context/snackbar-context';
import 'react-perfect-scrollbar/dist/css/styles.css';

import { queryClient } from '../../../utils/cms';
import { useNotificationWebSocket } from '../../../hooks/use-notifcations';
// ** Custom Components and Utils Imports
import P4lIcon from '../../UI/P4lIcon'; // Adjust the import path as necessary
import { useQuery, useMutation } from '@tanstack/react-query';
import { fetchNotifications } from '../../../utils/cms'; // Ensure this utility is correctly implemented
import { markNotificationAsRead ,deleteNotifications} from '../../../utils/cms';

// ** Styled components
const Menu = styled(MuiMenu)(({ theme }) => ({
  '& .MuiMenu-paper': {
    width: 380,
    overflow: 'hidden',
    marginTop: theme.spacing(2),
    borderColor:'red',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  '& .MuiMenu-list': {
    padding: 0,
    maxHeight: '60vh', // Control the maximum height here
    overflowY: 'hidden', // Enable vertical scrolling
    overflowX: 'hidden', // Hide horizontal scrollbar
    scrollbarWidth: 'none',  /* Firefox */
    '&::-webkit-scrollbar': {
      display: 'none',  /* Chrome, Safari, Opera*/
  }
}
}));

const HiddenScrollbar = styled('div')(({ theme }) => ({
  maxHeight: '60vh',
  overflowY: 'scroll',
  msOverflowStyle: 'none',  /* IE and Edge */
  scrollbarWidth: 'none',  /* Firefox */
  '&::-webkit-scrollbar': {
    display: 'none',  /* Chrome, Safari, Opera*/
  }
}));

const MenuItem = styled(MuiMenuItem)(({ theme }) => ({
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  '&:not(:last-of-type)': {
    borderBottom: `1px solid ${theme.palette.divider}`,
  }
}));




const Notifications = (props) => {

  const [anchorEl, setAnchorEl] = useState(null);
  const [sortedNotifications, setSortedNotifications] = useState([]);

  const [unreadNotifications, setUnreadNotifications] = useState(0);


  const { notify } = useSnackBar();


  const [notificationsAlert, setNotificationsAlert] = useState([]);

  const handleNewNotification = useCallback((newNotification) => {
    setNotificationsAlert(prevNotifications => [newNotification, ...prevNotifications]);
  }, []);
useNotificationWebSocket(handleNewNotification);
//console.log(notificationsAlert);
  const { data: notifications,refetch } = useQuery({
    queryKey: ['notifications'],
    gcTime:5000,
    queryFn: () => fetchNotifications() // Ensure you have this fetching logic implemented
  })

  const { mutate  } = useMutation({
    mutationFn: deleteNotifications, //delete event fn from utils/ http.js
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['notifications'], //trigger the refetching of the events list after deletion
        refetchType: 'none' //disable the imediate refetching in the background in the event detail that we just deleted to avoid errors
      });

    }
  });

 // useNotificationWebSocket();

//console.log(props.translations)
 useEffect(() => {
  // Calculate the unread notifications count from notificationsAlert
 refetch();
  const countFromAlert = notificationsAlert?.filter(n => !n.is_read).length || 0;
  //console.log(notifications);
  // If countFromAlert is 0, then calculate from notifications
  if (countFromAlert === 0) {
    //console.log(notifications);
    const countFromNotifications = notifications?.filter(n => !n.is_read).length || 0;
    setUnreadNotifications(countFromNotifications);
  } else {
    // Use the count from notificationsAlert if it's not zero
    setUnreadNotifications(countFromAlert);
  }
  // eslint-disable-next-line
}, [notificationsAlert, notifications]);
  //console.log( notifications?.filter(n => !n.is_read));
  async function handleDropdownOpen (event) {
    setAnchorEl(event.currentTarget);
    // Assuming markNotificationAsRead updates the server and refetches notifications
    notifications?.filter(n => !n.is_read).forEach(n => markNotificationAsRead(n.id));
    await refetch();
    setNotificationsAlert([]);
    setUnreadNotifications(0);

  }

  const handleDropdownClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleDelete = (id) => {
    mutate({ id: id }, {
      onSuccess: async () => { // Make this function async
        await refetch(); // Now you can await here
        // Filter out the deleted notification from the sorted list
        setSortedNotifications(notifications?.slice().sort((a, b) => new Date(b.created) - new Date(a.created)) || []);
      }
    });
  };

  useEffect(() => {
    if (!notificationsAlert ) return;
    //console.log(notificationsAlert)
    // Check if there are new notifications
    const newNotifications = notificationsAlert.filter(notification => !notification.is_read);
    //console.log(newNotifications)
    if (newNotifications.length > 0) {
      // Show the notification using the snackbar

      newNotifications.forEach(notification => {
        notify(notification.message,notification.level);
      });

      // Update the notificationDisplayed flag

    }
  }, [notificationsAlert ,notify]);

  useEffect(() => {

    setSortedNotifications( notifications?.slice().sort((a, b) => new Date(b.created) - new Date(a.created)) || []);

  }, [notifications]);







  function formatDate(date) {
    const today = new Date();
   // today.setHours(0, 0, 0, 0); // Reset time part to compare dates only

    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1); // Subtract a day to get yesterday

    const givenDate = new Date(date);
    //givenDate.setHours(0, 0, 0, 0); // Reset time part to compare dates only

    const time = givenDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' });

    if (givenDate.getTime() === today.getTime()) {
      return `Hoje, ${time}`;
    } else if (givenDate.getTime() === yesterday.getTime()) {
      return `Ontem, ${time}`;
    } else {
      return `${new Intl.DateTimeFormat('pt', {
        day: '2-digit',
        month: 'short',
      }).format(givenDate)}, ${time}`;
    }
  }



//console.log(sortedNotifications)
  return (
    <Fragment>
      <Tooltip title={props.translations?.notifications?.label}>
        <P4lIcon
          ariaLabel="show notifications"
          icon="p4l-bell" // Use the correct class name for your bell icon
          size="medium"
          onClick={handleDropdownOpen}
          badgeContent={unreadNotifications}
          disableRipple={false}
        />
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleDropdownClose}
      >

<HiddenScrollbar>
{sortedNotifications.length > 0 ? (
      sortedNotifications.map((notification, index) => (
        <MenuItem
          key={index}
          sx={{
            padding: '1rem', // Adjust padding to fit the layout
            display: 'block',   // Ensure block layout for proper wrapping
            wordBreak: 'break-word', // Force word break
            whiteSpace: 'normal',  // Ensure text wraps correctly
            overflowWrap: 'break-word', // Support for older browsers
            width: '100%',
          }}
        >
          <Box className="p-2" sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <div className="flex justify-between gap-6 items-start w-full ">
              <Typography variant="body1"  className="flex-grow break-words mr-2">
                {notification.message}
              </Typography>
              <P4lIcon onClick={() => handleDelete(notification.id)} size="small" icon="p4l-bin" className="flex-shrink-0" />
            </div>
            <Typography variant="body2" color="text.secondary">
              {formatDate(notification.created)}
            </Typography>
          </Box>
        </MenuItem>
      ))
    ) : (
      <Box sx={{ padding: '1rem', textAlign: 'center' }}>
           <Typography variant="body1" fontWeight={600} color="text.secondary">
          {props.translations.no_notifications.label}
        </Typography>
      </Box>
    )}
</HiddenScrollbar>
      </Menu>
    </Fragment>
  );
};

export default React.memo(Notifications);
