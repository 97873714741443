import React, { useState, useEffect } from 'react';
import Button from '../components/UI/Button';
import { Box,Typography} from '@mui/material';


function OnBoarding() {
  const removePreloader = () => {
    const preloader = document.getElementById('preloader');
    if (preloader) {
      preloader.classList.add('hidden'); // Add the `hidden` class to fade out

    }
  };

    useEffect(() => {

      removePreloader();
    }, []);

  return (
    <>


            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
            gap={8}
            padding={6}
              height="100vh"
              textAlign="center"
            >
   <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
               Identificação Fiscal
              </Typography>
   <Typography variant="h6" sx={{mt: 2 }}>
   Inicie o processo de identificação fiscal para poderemos dar-lhe um melhor serviço.
              </Typography>
<img className='max-w-md' src='https://images.blush.design/1Gjt02zbWV20yIqXNFxw?w=920&auto=compress&cs=srgb' />


<Button className="w-full sm:w-auto " buttonText={"Avançar"} variant="contained" />

            </Box>



    </>
  );
}

export default OnBoarding;
