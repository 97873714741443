import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import P4lIcon from "../UI/P4lIcon";
import Cookies from "js-cookie";
import { fetchCompanyDetails } from "../../utils/cms";
import LanguageSelector from "../UI/LanguageSelector";

function SocialAuth({ social }) {
  const [socialAuthOptions, setSocialAuthOptions] = useState([]);

  useEffect(() => {
    const companyName = Cookies.get('brand') || 'planning4life';

    const fetchCompanyData = async () => {
      const companyDetails = await fetchCompanyDetails(companyName);
      if (companyDetails && companyDetails.data && companyDetails.data['social-auth']) {
        setSocialAuthOptions(companyDetails.data['social-auth'].filter(option => option.active === 1));
      }
    };

    fetchCompanyData();
  }, []);

  return (
    <React.Fragment>
      <Box>
        <div className="flex flex-col justify-start gap-8 items-start">
          <div className="w-full flex justify-center">
            {social !== 'disable' && socialAuthOptions.map((option, index) => (
              <P4lIcon
                key={index}
                href={option.url}
                disableRipple={true}
                icon={option.icon}
                className="text-white"
              />
            ))}
          </div>

          {/* Language Selector Component */}
          <LanguageSelector />
        </div>
      </Box>
    </React.Fragment>
  );
}

export default SocialAuth;
