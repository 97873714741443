import { Outlet } from 'react-router-dom';
import Nav from '../components/Nav/Nav';
import Box from '@mui/material/Box';
import { fetchHourGlassLogos } from '../utils/cms';
import Footer from '../components/Footer/Footer';
import MainSection from '../components/UI/MainSection';
import FloatingMenu from '../components/Nav/FloatingMenu/FloatingMenu';
import React, { useState, useEffect, useRef, useCallback, useContext,useMemo } from 'react';
import AuthContext from '../store/context/auth-context__';
import { useLoaderData,useLocation } from 'react-router-dom';
import { useNavigate, useNavigation } from 'react-router-dom';
import { fetchMenuItems } from '../utils/cms';
import HourGlass from '../components/UI/HourGlass';

import ScrollToTop from '../components/ScrollToTop';
import P4lIcon from '../components/UI/P4lIcon';

function Root() {
  const drawerWidth = 300;
  const [open, setOpen] = useState(false);
  const navigation = useNavigation();
  const navigate = useNavigate();
  const location = useLocation();
  const loaderData = useLoaderData();
  const footerRef = useRef(null);
console.log(loaderData);
  const { sideBarItems, topNavItems, footerItems, floatingMenuItems } = loaderData || {};
  const [isLoading, setIsLoading] = useState(true);
//console.log(topNavItems);
  const authCtx = useContext(AuthContext);
  const isHomePage = location.pathname === '/';
  const buttonStickyRef = useRef(null);

  const removePreloader = () => {
    const preloader = document.getElementById('preloader');
    if (preloader) {
      preloader.classList.add('hidden'); // Add the `hidden` class to fade out

    }
  };
  // Handle authentication
  useEffect(() => {
    authCtx.refetch();
    //console.log(authCtx.isLoggedIn);
    if (authCtx.isLoggedIn===false) {
      navigate('/login', { replace: true });
    }
    // eslint-disable-next-line
  }, [authCtx.isLoggedIn, navigate]);

  // Handle loader data
  useEffect(() => {
    if (loaderData) {
      setIsLoading(false);

    }
    removePreloader();
  }, [loaderData]);

  const handleDrawerOpen = useCallback(() => setOpen(true), []);
  const handleDrawerClose = useCallback(() => setOpen(false), []);


  useEffect(() => {
    // Access the DOM directly to check for content
    buttonStickyRef.current = document.getElementById('button-sticky');
    if(!buttonStickyRef.current) return;
    const checkContentAndToggleVisibility = () => {
      if (buttonStickyRef.current) {
        if (buttonStickyRef.current.innerHTML.trim() === '') {
          buttonStickyRef.current.classList.add('hidden');
        } else {
          buttonStickyRef.current.classList.remove('hidden');
        }
      }
    };

    // Call the function to check content immediately after any route changes
    checkContentAndToggleVisibility();

    // Optional: Watch for changes in the content of the div
    const observer = new MutationObserver(checkContentAndToggleVisibility);
    observer.observe(buttonStickyRef.current, { childList: true, subtree: true });

    return () => observer.disconnect(); // Clean up the observer when the component unmounts or location changes
  }, [location]); // Rerun the effect when the location changes



  // Combined loading state
  const combinedLoading = isLoading || navigation.state === 'loading' ;

  const hourGlassType = combinedLoading && (
    navigation.location?.pathname === '/pages/my-credits' ? 'credit' :
    navigation.location?.pathname === '/pages/my-insurances' ? 'insurance' :
    undefined
);

  return (
    <React.Fragment>
      {authCtx.isLoggedIn===true ? (
        <ScrollToTop>
          <Box className="w-full" sx={{ height: '100%', display: 'flex' }}>
            <Nav
              topNavItems={topNavItems}
              sideBarItems={sideBarItems}
              drawerWidth={drawerWidth}
              open={open}
              handleDrawerClose={handleDrawerClose}
              handleDrawerOpen={handleDrawerOpen}
            />
            <Box
              sx={{
                flexGrow: 1,
                minWidth: 0,
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
              }}
              className="w-full bg-primary-300"
            >
              <main
                sx={{
                  position: 'relative',
                  flexGrow: 1,
                  width: '100%',
                  transition: 'padding .25s ease-in-out',
                }}
              >
                {!isHomePage && navigation.state !== 'loading' && (
                  <div className="w-full sm:hidden bg-primary-white min-h-10 sticky top-16 z-50 pl-3">
                    <P4lIcon
                      icon="p4l-arrow-left2 text-2xl cursor-pointer"
                      size="small"
                      onClick={() => navigate(-1)}
                    />
                  </div>
                )}
                <MainSection>
                  <FloatingMenu footerRef={footerRef} data={floatingMenuItems} />
                  {combinedLoading ? (
               <HourGlass images={hourGlassType  ? loaderData.hourGlassLogos[hourGlassType] : []} type={hourGlassType} />
                  ) : (
                    <Outlet />
                  )}
                </MainSection>
                <div ref={buttonStickyRef} id="button-sticky" className="sticky bottom-16 p-8"></div>
              </main>
              <Footer ref={footerRef} footerItems={footerItems} />
            </Box>
          </Box>
        </ScrollToTop>
      ) : (
        <Box
          className="flex justify-center items-center h-screen"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '100vh',
            width: '100%',
          }}
        >
        <div className='bg-white w-full flex justify-center  items-center min-h-screen'><img className='max-w-xs' src={`${process.env.PUBLIC_URL}/static/images/loading.gif`} alt="Logo"/></div>
        </Box>
      )}
    </React.Fragment>
  );
}

export default Root;


export async function loader() {

  let menuItemsData = null;
  let hourGlassLogos= null;

  try {
    menuItemsData = await fetchMenuItems('all');

  } catch (error) {
    console.error("Error fetching  menus", error);
    // Optionally set fallback data here
  }

  try {
    hourGlassLogos = await fetchHourGlassLogos();

  } catch (error) {
    console.error("Error fetching  hourglass logos", error);
    // Optionally set fallback data here
  }


//console.log(menuItemsData)

  const data = {
    sideBarItems: {items:menuItemsData?.items["sidebar-menu"]},
    topNavItems: {items:menuItemsData?.items["top-menu"],translation_items:menuItemsData?.translation_items,profiles:menuItemsData?.profiles

    },
    footerItems: {items:menuItemsData?.items["footer-menu"]},
    floatingMenuItems : {items:menuItemsData?.items["floating-menu"]},
    hourGlassLogos:hourGlassLogos,

  };

  //console.log(data);

  return data;
}
