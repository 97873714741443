import { Box,CircularProgress } from "@mui/material";
import CardMessageButton from "../components/UI/CardMessageButton";
import { fetchCompanyDetails } from "../utils/cms";
import Cookies from "js-cookie";
import { listSocialAccounts } from "../utils/auth";
import { useLoaderData } from "react-router-dom";
import { deleteSocialAccount } from "../utils/auth";
import React,{useState,useEffect} from "react";
import Tabs from "../components/UI/DynamicForm/Tabs";
import { useTranslationsContext } from "../store/context/translations-context";
export default function AccountConfig() {
    const loaderData = useLoaderData();
const { requestTranslation } = useTranslationsContext();
 const [isTranslationsLoading, setIsTranslationsLoading] = useState(true);
  const [associatedAccountsLabel, setAssociatedAccountsLabel] = useState("");

  useEffect(() => {
    const fetchTranslations = async () => {
      try {

        const associatedAccountsLbl = await requestTranslation("associated-accounts");
setAssociatedAccountsLabel(associatedAccountsLbl);
      } catch (error) {
        console.error("Error fetching translations:", error);
      } finally {
        setIsTranslationsLoading(false);
      }
    };

    fetchTranslations();
  }, [requestTranslation]);


    console.log(loaderData);
    // Fallback content if no data is available


    const handleAssociateSocialAccount = async (url, connected,provider) => {
        if (!connected) {
          window.location.href = url; // This navigates the user to the external URL
        }
        else {
            // If connected, attempt to delete the account
            const result = await deleteSocialAccount(provider);
            if (result && result.ok) { // Check if the API response is OK
              window.location.reload(); // Reload the page to reflect changes
            } else {
              console.log('Failed to disconnect the account. Please try again.'); // Inform the user on failure
            }
          }
        // You can add else logic here if needed for connected accounts
      };

      if (!loaderData?.companyDetails || isTranslationsLoading) {
        return (
              <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                <CircularProgress />
              </Box>
            );
      }

      const tabs = [
        {
            "value": "associated-accounts",
            "label": associatedAccountsLabel,
        }
      ];

      const selectedTab = "associated-accounts";

    return (
        <Tabs className="w-full " tabs={tabs} selectedTab={selectedTab} >
      <Box className="flex flex-wrap gap-6 justify-center mt-6 p-6">
        {loaderData.companyDetails.map((option) => (
          <CardMessageButton
            key={option.allauth}
            text={option.title}
            buttonType={option.connected  ? "outlined" : "contained"}
            subText={`Pode usar a sua conta ${option.title.split(" ")[0]} para iniciar a sessão`}
            buttonText={option.connected ? "Remover ligação de conta" : "Associe a sua conta"}
            icon={`${option.icon}`}
            onClick={() => handleAssociateSocialAccount(option.url_connect_account, option.connected,option.provider_id)}
          />
        ))}
      </Box>
      </Tabs>
    );
  }


  export const loader = async () => {
    const companyName = Cookies.get('brand') || 'planning4life';

    try {
      const companyDetails = await fetchCompanyDetails(companyName);
      const socialAccounts = await listSocialAccounts();
      const filteredCompanyDetails = companyDetails.data['social-auth'].filter(option => option.active === 1);

      // Map through each company detail and add a 'connected' property based on social accounts
      const detailsWithConnection = filteredCompanyDetails.map(detail => ({
        ...detail,
        connected: socialAccounts.some(account => account.provider === detail.provider_id),
      }));

      return {
        companyDetails: detailsWithConnection,
        getSocialAccounts: socialAccounts,
      };
    } catch (error) {
      console.error('Error loading Company Details:', error);
      return {
        companyDetails: null,
        getSocialAccounts: null,
      };
    }
  };
