import { redirect } from 'react-router-dom';
import { json } from 'react-router-dom';
export function getAuthToken() {
    const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
let token;

token = urlParams.get('jwt_token');
//console.log(token);
if(token){
    localStorage.setItem('token',token);
}
    else{
        token = localStorage.getItem('token');
    }

    return token;
  }




  export async function tokenValidator(token) {
    const { REACT_APP_API_URL } = process.env;

    // Helper function to validate token
    async function validateToken(token) {
        if(!token) return;
        const response = await fetch(`${REACT_APP_API_URL}myuser/me/`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });


        if ( !response.ok) {
          return null;
        }

        return response; // Return the fetch response for further processing

    }

    // Helper function to refresh token
    async function refreshToken(refreshToken) {
      try {
        const response = await fetch(`${REACT_APP_API_URL}myuser/token/refresh/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ refresh: refreshToken }),
        });
        if (!response.ok) {
         return null;
        }
        const resData = await response.json();
        return resData.access; // Return the new access token
      } catch (error) {
        // Handle specific token refresh errors here if needed
        //console.log(error); // Re-throw to be handled by the caller
      }
    }

    try {
      let response = await validateToken(token);
      //console.log(response);
      if (!response || !response.ok) {
        const refresh = localStorage.getItem('refresh');
        //console.log(refresh)
        if (!refresh) {
          localStorage.removeItem('token');
          localStorage.removeItem('refresh');
          localStorage.removeItem('userData');
          return false;
        }
        const newToken = await refreshToken(refresh);
        //console.log(newToken);
        localStorage.setItem('token', newToken);
        response = await validateToken(newToken); // Retry validation with the new token
        //console.log(response)
        if (!response.ok) {
          localStorage.removeItem('token');
          localStorage.removeItem('refresh');
          localStorage.removeItem('userData');
          return false;
        }
      }

      if ([401, 422].includes(response.status)) {
        // Specific error handling for 401 Unauthorized or 422 Unprocessable Entity
        return false;
      }

      const userData = await response.json();
      //console.log(userData);
      localStorage.setItem('userData', JSON.stringify(userData));
      return true; // Token is valid and user data is stored

    } catch (error) {
      console.error(error.message);
      localStorage.removeItem('token');
      localStorage.removeItem('refresh');
      localStorage.removeItem('userData');
      return false; // Return false or handle the error as needed
    }
  }



  export async function tokenLoader() {
    //console.log("entra aqui")
    const token = getAuthToken();
    //console.log(token);
    const validToken = await tokenValidator(token);
    //console.log(validToken)
    const language = localStorage.getItem('language');
    if(!language)
    {
        localStorage.setItem('language','pt');

    }
    if (!validToken){
      //console.log("devia remover")
      localStorage.removeItem('userData');
      localStorage.removeItem('token');
      localStorage.removeItem('refresh');
        return redirect('/login');
    }

    //console.log(token);

    return token;
  }



  export function getCookie (name) {
    let cookieValue = null
    if (document.cookie && document.cookie !== '') {
      const cookies = document.cookie.split(';')
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim()
        // Does this cookie string begin with the name we want?
        if (cookie.substring(0, name.length + 1) === (name + '=')) {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1))
          break
        }
      }
    }
    return cookieValue
  }
  export function getCSRFToken () {
    return getCookie('csrftoken');
  }

  export function getAccessToken () {
    return getCookie('mid_access_token');
  }


  export function getSessionID () {
    return getCookie('sessionid');
  }




  export const disableUser = async () => {

    //console.log(objDetails);

    const url = `appauth/disable-user`;
    //console.log(url);

    const token = getCSRFToken();
  ;

    try {
      const headers = token ? {  'X-CSRFToken': token, 'Content-Type': 'application/json' } : { 'Content-Type': 'application/json' };
      const body = {
       confirmation:''

      };
        //console.log(body)
      const response = await fetch(`${process.env.REACT_APP_API_URL}${url}`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(body),
        credentials:"include"
      });

      if (!response.ok) {
        //console.log(response)
        throw json({ message: response.statusText }, { status: response.status });
      }

      const responseData = await response.json();
      //console.log(responseData)
      return responseData.data;
    } catch (error) {
      //console.log(error)
      throw json({ message: error.text }, { status: error.status });
    }

  }


  export const changePassword = async (currentPassword, newPassword) => {
    const url = `_allauth/browser/v1/account/password/change`;

    const token = getCSRFToken();

    try {
      const headers = token
        ? { "X-CSRFToken": token, "Content-Type": "application/json" }
        : { "Content-Type": "application/json" };

      const body = {
        current_password: currentPassword,
        new_password: newPassword,
      };

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}${url}`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify(body),
          credentials: "include",
        }
      );

      if (!response.ok) {
        throw new Error("Failed to change password");
      }

      return await response.json();
    } catch (error) {
      throw new Error(error.message);
    }
  };


  export const listSocialAccounts = async () => {
    const url = `appauth/social/social-accounts`;
    const token = getCSRFToken();

      try {

        const headers = token ? {   'X-CSRFToken': token } : {};
        const response = await fetch(`${process.env.REACT_APP_API_URL}${url}`, {
          headers: headers,
           credentials:"include"
        });
        const data = await response.json();


        return data;
      } catch (error) {
        console.error(`Error fetching social accounts`, error);
        return null; // Return null or appropriate error handling
      }
    };

    export const deleteSocialAccount = async (provider) => {
      const url = `appauth/social/social-accounts/unlink/?provider=${provider}`;
      const token = getCSRFToken();

        try {

          const headers = token ? {   'X-CSRFToken': token } : {};
          const response = await fetch(`${process.env.REACT_APP_API_URL}${url}`, {
            method:"DELETE",
            headers: headers,
             credentials:"include"
          });
         await response.json();


          return response;
        } catch (error) {
          console.error(`Error deleting social account`, error);
          return null; // Return null or appropriate error handling
        }
      };

