import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { CircularProgress } from "@mui/material";
import Breadcrumb from "../components/Breadcrumb/Breadcrumb";
import { useTranslationsContext } from "../store/context/translations-context";
import { fetchFinancialDetails } from "../utils/cms";
import FinancialStatusNotInit from "../components/FinanceStatus/FinancialStatusNotInit";
import FinancialStatusPending from "../components/FinanceStatus/FinancialStatusPending";
import FinancialStatusValid from "../components/FinanceStatus/FinancialStatusValid";
import FinancialStatusInvalid from "../components/FinanceStatus/FinancialStatusInvalid";
import FinancialStatusEdit from "../components/FinanceStatus/FinancialStatusEdit"; // Import the FinancialStatusEdit component
import Button from "../components/UI/Button";
import { useLoaderData } from "react-router-dom";
const Financials = () => {


  const [showEditCredentials, setShowEditCredentials] = useState(false); // State to toggle edit credentials form
  const [isLoading, setIsLoading] = useState(false); // State for loading spinner

  const loaderData = useLoaderData();
  const {financialData} = loaderData;







  if ( !financialData) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <CircularProgress />
      </Box>
    );
  }

  const { credentials_status, official_status } = financialData;

  const renderContent = () => {
    // Toggle between showing financial status or the edit credentials form
    if (showEditCredentials) {
      return (
       <div className="flex flex-col">
        <Button
        className="w-fit"
        variant="outlined"
        color="primary"
        onClick={() => setShowEditCredentials(false)} // Trigger going back to FinancialStatusValid
        buttonText={financialData.translations_items.financial.back.label} // Use translated "Back" text
      />

        <FinancialStatusEdit
          translatedLabels={financialData.translations_items.financial}
          instructions={financialData.translations_items.financial.financial_instructions.label}
          disclaimer={financialData.translations_items.financial.financial_disclaimer.label}

        />

</div>
      )
    }

    // Render based on credentials_status and official_status
    if (credentials_status.status === 'PENDING') {
      return <FinancialStatusPending />;
    } else if (credentials_status.status === 'INVALID' || credentials_status.status === 'EXPIRED') {
      return (
        <FinancialStatusInvalid
          translatedLabels={financialData.translations_items.financial}
          instructions={financialData.translations_items.financial.financial_instructions.label}
          disclaimer={financialData.translations_items.financial.financial_disclaimer.label}
        />
      );
    } else if (credentials_status.status === 'VALID') {
      return (
        <>
              <Button
            variant="contained"
            color="primary"

            onClick={() => setShowEditCredentials(true)} // Show edit credentials form
            buttonText={financialData.translations_items.financial.edit_credentials.label} // Use translated button text
            isLoading={isLoading} // Pass loading state
          />
          <FinancialStatusValid translatedLabels={financialData.translations_items} officialStatus={official_status} />

        </>
      );
    }
   else {
      return (
        <FinancialStatusNotInit
          translatedLabels={financialData.translations_items.financial}
          instructions={financialData.translations_items.financial.financial_instructions.label}
          disclaimer={financialData.translations_items.financial.financial_disclaimer.label}
        />
      );
    }
  };

  return (
    <>
      <Breadcrumb
        data={[
          { label:financialData.translations_items.financial.home.label, url: "/" },
          { label: financialData.translations_items.financial.profile.label, url: "/profile" },
          { label: financialData.translations_items.financial.financials.label, url: "/profile/financials" },
        ]}
      />

      <Box className="w-full p-6 bg-white">
        <Box className="w-full" sx={{ mb: 3 }}>
          <h3 className="mb-6">{financialData.translations_items.financial.financials.label}</h3>

          {/* Render Content based on credentials_status and official_status */}
          {renderContent()}
        </Box>
      </Box>
    </>
  );
};

export default Financials;


export const loader = async () => {


  try {
    const financialData = await fetchFinancialDetails();  // Fetch profile details


    return {
      financialData: financialData || null,

    };
  } catch (error) {
    console.error('Error loading profile data:', error);
    return {
      financialData: null,

    };
  }
};