import React from "react";
import { format } from "date-fns";
import { fetchFinancialDetails } from "../utils/cms";
import { fetchIntegrationDetails } from "../utils/cms";
import { Box } from "@mui/material";

import { financeAtCheck, insuranceCheck,integrationCredentialStatus } from "../utils/cms";
import CardMessageButton from "../components/UI/CardMessageButton";
import { useNavigate } from "react-router-dom";
import Tabs from "../components/UI/DynamicForm/Tabs";
import { useLoaderData } from "react-router-dom";
export default function Integrations() {

  const navigate = useNavigate();
 const loaderData = useLoaderData();
console.log(loaderData);
const {integrationData ,financialData,integrationCredentials } = loaderData;


  const handleRefreshFinance = async () => {
    try {
      const response = await financeAtCheck();
      if (response) {
        navigate("/notification", {
          state: {
            messages: {
              title: "at-status-update",
              success: "at-status-update-success",
              instructions: "at-status-update-instructions",
            },
            buttons: [
              {
                text: "back",
                link: "/profile/integrations",
              },
            ],
          },
        });
      }
    } catch (error) {
      console.error("Error triggering finance check:", error);
    }
  };

  const handleRefreshInsurance = async () => {
    try {
      const response = await insuranceCheck();

      if (response) {

        navigate("/notification", {
          state: {
            messages: {
              title: "insurance-status-update",
              success: "insurance-status-update-success",
              instructions: "insurance-status-update-instructions",
            },
            buttons: [
              {
                text: "back",
                link: "/profile/integrations",
              },
            ],
          },
        });
      }
    } catch (error) {
      console.error("Error triggering insurance check:", error);
    }
  };


const tabs = [
  {
      "value": "integrations",
      "label": integrationData.translations_items.integrations.integrations.label,
  }
];

const selectedTab = "integrations";
  return (
    <Tabs className="w-full " tabs={tabs} selectedTab={selectedTab} >
    <Box className="flex flex-wrap gap-6 justify-center mt-6 p-6">
      <CardMessageButton
        text={integrationData.translations_items.integrations.insurance_unipeople.label}
        subText={`${integrationData.translations_items.integrations.last_updated.label}: ${financialData?.insurance?.last_updated || "N/A"}`}
        buttonText={financialData?.insurance?.last_updated ? integrationData.translations_items.integrations.buttons.connected.label : integrationData.translations_items.integrations.buttons.not_connected.label }
        image = {integrationData.translations_items.integrations.insurance_unipeople.image_url}
        onClick={handleRefreshInsurance}
      />
      <CardMessageButton
        text={integrationData.translations_items.integrations.finances_portal.label}
        subText={`${integrationData.translations_items.integrations.last_updated.label}: ${financialData?.official_status?.last_updated && format(
          new Date(financialData.official_status.last_updated),
          "yyyy-MM-dd HH:mm:ss"
        )  || "N/A"}  `}

        buttonText={financialData?.official_status?.last_updated ? integrationData.translations_items.integrations.buttons.connected.label : integrationData.translations_items.integrations.buttons.not_connected.label }
        image = {integrationData.translations_items.integrations.finances_portal.image_url}
        onClick={financialData?.official_status?.last_updated && handleRefreshFinance}
        buttonLink={!financialData?.official_status?.last_updated && "/profile/financials" }
      />
        <CardMessageButton
        text={integrationData.translations_items.integrations.xtb.label}
        subText={`${integrationData.translations_items.integrations.last_updated.label}: ${integrationCredentials && integrationCredentials?.statuses["XTB"]?.last_updated && format(
          new Date(integrationCredentials?.statuses["XTB"].last_updated),
          "yyyy-MM-dd HH:mm:ss"
        )  || "N/A"}  `}

        buttonText={integrationCredentials && integrationCredentials?.statuses["XTB"] ? integrationData.translations_items.integrations.buttons.connected.label : integrationData.translations_items.integrations.buttons.not_connected.label}
        image = {integrationData.translations_items.integrations.xtb.image_url}
        buttonLink={"/profile/integrations/integration-init?id=xtb"}
      />
         <CardMessageButton
        text={integrationData.translations_items.integrations.ageas.label}
        subText={`${integrationData.translations_items.integrations.last_updated.label}: ${integrationCredentials && integrationCredentials?.statuses["AGEAS"]?.last_updated && format(
          new Date(integrationCredentials?.statuses["AGEAS"].last_updated),
          "yyyy-MM-dd HH:mm:ss"
        )  || "N/A"}  `}

        buttonText={integrationCredentials && integrationCredentials?.statuses["AGEAS"] ? integrationData.translations_items.integrations.buttons.connected.label : integrationData.translations_items.integrations.buttons.not_connected.label}
        image = {integrationData.translations_items.integrations.ageas.image_url}
        buttonLink={"/profile/integrations/integration-init?id=ageas"}
      />
         <CardMessageButton
        text={integrationData.translations_items.integrations.fidelidade.label}
        subText={`${integrationData.translations_items.integrations.last_updated.label}: ${integrationCredentials && integrationCredentials?.statuses["FIDELIDADE"]?.last_updated && format(
          new Date(integrationCredentials?.statuses["FIDELIDADE"].last_updated),
          "yyyy-MM-dd HH:mm:ss"
        )  || "N/A"}  `}

        buttonText={integrationCredentials && integrationCredentials?.statuses["FIDELIDADE"] ? integrationData.translations_items.integrations.buttons.connected.label : integrationData.translations_items.integrations.buttons.not_connected.label}
        image = {integrationData.translations_items.integrations.fidelidade.image_url}
        buttonLink={"/profile/integrations/integration-init?id=fidelidade"}
      />
         <CardMessageButton
        text={integrationData.translations_items.integrations.ss.label}
        subText={`${integrationData.translations_items.integrations.last_updated.label}: ${integrationCredentials && integrationCredentials?.statuses["SS"]?.last_updated && format(
          new Date(integrationCredentials?.statuses["SS"].last_updated),
          "yyyy-MM-dd HH:mm:ss"
        )  || "N/A"}  `}

        buttonText={integrationCredentials && integrationCredentials?.statuses["SS"] ? integrationData.translations_items.integrations.buttons.connected.label : integrationData.translations_items.integrations.buttons.not_connected.label}
        image = {integrationData.translations_items.integrations.ss.image_url}
        buttonLink={"/profile/integrations/integration-init?id=ss"}
      />
    </Box>
    </Tabs>
  );
}


export const loader = async () => {


  try {
    const integrationData = await fetchIntegrationDetails();  // Fetch profile details
    const financialData = await fetchFinancialDetails();  // Fetch profile details
    const integrationCredentials = await integrationCredentialStatus();
    return {
      integrationData: integrationData || null,
      financialData: financialData || null,
      integrationCredentials : integrationCredentials || null,
    };
  } catch (error) {
    console.error('Error integration data:', error);
    return {
      integrationData: null,
      financialData:null,
      integrationCredentials:null,

    };
  }
};